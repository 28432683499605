import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import Layout from 'components/layout';
import Sections from 'components/sections';
import useStoryblok from 'lib/hooks/useStoryblok';
import fs from 'node:fs';
import { getPageData } from 'lib/storyblok/getPageData';
import { getGlobalData } from 'lib/storyblok/getGlobalData';
import { getPages } from 'lib/storyblok/getPages';

export default function DynamicPage({ globalData, pageData, preview }) {
  const router = useRouter();
  const story = useStoryblok(pageData, preview);

  if (router.isFallback) {
    return <div>Loading...</div>;
  }

  return (
    <Layout {...globalData} {...story.content}>
      <Sections sections={story.content.body} />
    </Layout>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  const pages = await getPages();
  const paths = pages.map((page) => ({
    params: { slug: page.path },
    locale: page.locale,
  }));

  if (process.env.NODE_ENV === 'production') {
    // Trick to exclude pages with noIndex from the sitemap
    const noIndexed = pages
      .filter((page) => page.noIndex)
      .map((page) => {
        const path = page.path.join('/');
        return page.locale !== 'en' ? `/${page.locale}/${path}` : `/${path}`;
      })
      .join('|');
    fs.writeFileSync('./temp/noIndexedPaths', noIndexed);
  }

  return { paths, fallback: false };
};

export const getStaticProps: GetStaticProps = async ({ params: { slug: slugArray }, preview = false, locale }) => {
  const pageData = await getPageData(slugArray, {
    locale,
    preview,
  });

  if (!pageData) {
    return {
      notFound: true,
    };
  }

  const globalData = await getGlobalData({
    locale,
    preview,
  });

  return {
    props: {
      globalData,
      pageData,
      preview,
    },
  };
};
