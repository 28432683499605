import { useCallback, useEffect, useState } from 'react';

export default function useStoryblok(originalStory, preview) {
  const [story, setStory] = useState(originalStory);

  // adds the events for updating the visual editor
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
  const initEventListeners = useCallback(() => {
    if (window.storyblok) {
      window.storyblok.init();

      // reload on Next.js page on save or publish event in the Visual Editor
      window.storyblok.on(['change', 'published'], () => location.reload());

      // live update the story on input events
      window.storyblok.on('input', (event) => {
        if (event.story.content._uid === story.content._uid) {
          event.story.content = window.storyblok.addComments(event.story.content, event.story.id);
          setStory(event.story);
        }
      });
    }
  }, [story]);

  // appends the bridge script tag to our document
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
  function addBridge(callback) {
    // check if the script is already present
    const existingScript = document.getElementById('storyblokBridge');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://app.storyblok.com/f/storyblok-latest.js?t=${process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN}`;
      script.id = 'storyblokBridge';
      document.body.appendChild(script);
      script.onload = () => {
        // once the scrip is loaded, init the event listeners
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    setStory(originalStory);
    // first load the bridge, then initialize the event listeners
    if (preview) {
      addBridge(initEventListeners);
    }
  }, [originalStory, preview, initEventListeners]);

  return story;
}
